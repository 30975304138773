<template>
  <c-card>
    <b-alert :show="$route.query.uniqid ? true : false" variant="primary">
      <div class="alert-body">
        Anda sedang melakukan edit transaksi. Tekan tombol <b class="text-danger"><feather-icon icon="XIcon"></feather-icon> Reset</b> jika ingin membatalkan edit dan melakukan transaksi baru.
      </div>
    </b-alert>
    <div class="d-flex justify-content-end">
      <small>Last Syncronize : <span class="font-weight-bolder">{{lastSync | fulldate}}</span></small>
    </div>
    <b-row>
      <b-col sm="12" offset-xl="2" xl="10">
        <b-row cols-sm="2" cols-xl="4">
          <b-col>
            <form-input readonly :label-cols="3" size="sm" v-model="formData.code_ref" label="No"></form-input>
          </b-col>
          <b-col>
            <form-date disabled size="sm" :label-cols="3" v-model="formData.date" label="Tgl"></form-date>
          </b-col>
          <b-col>
            <b-button @click="$refs.transactionModal.show()" block size="sm" variant="primary"><feather-icon icon="SearchIcon"></feather-icon> Cari Penukaran</b-button>
          </b-col>
          <b-col>
            <c-button @click="syncItems" :loading="syncLoading" block size="sm"><feather-icon icon="DownloadCloudIcon"></feather-icon> Sinkronisasi Data Server</c-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <c-form ref="form" @submit="confirmSubmit">
      <span class="font-weight-bolder">Barang Masuk :</span>
      <b-table @row-clicked="itemsEditIn" class="mb-2 mt-1" striped borderless outlined :fields="inFields" :items="inDetails" foot-clone>
        <template #empty>
          <span>Tidak Ada Data.</span>
        </template>
        <template #cell(price)="{item}">
          Rp.{{item.price | formatNumber}}
        </template>
        <template #cell(price_total)="{item}">
          Rp.{{item.price_total | formatNumber}}
        </template>
        <template #cell(uniqid)="{index}">
          <b-button @click="deleteInDetail(index)" size="sm" variant="outline-danger">X</b-button>
        </template>
        <template #foot(code_ref)="{}">
          <b-button @click="$refs.localModal.show()" block variant="outline-primary"><feather-icon icon="MonitorIcon"></feather-icon> Cari Penjualan</b-button>
        </template>
        <template #foot()="{}">
          <span></span>
        </template>
      </b-table>
      <span class="font-weight-bolder">Barang Keluar :</span>
      <b-table-simple class="mb-2 mt-1" striped borderless outlined>
        <b-thead>
          <b-tr>
            <b-th>Kode Barang</b-th>
            <b-th>Merek</b-th>
            <b-th>Artikel</b-th>
            <b-th>Warna</b-th>
            <b-th>Nomor</b-th>
            <b-th>Qty</b-th>
            <b-th>Harga</b-th>
            <b-th>Total</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="pointer" @click="itemsEdit(k)" v-for="(v,k) in details" :key="k">
            <b-td>{{v.code}}</b-td>
            <b-td>{{v.brand_name}}</b-td>
            <b-td>{{v.code_article}}</b-td>
            <b-td>{{v.color_name}}</b-td>
            <b-td>{{v.size_name}}</b-td>
            <b-td>{{v.qty | formatNumber}}</b-td>
            <b-td>Rp.{{v.price | formatNumber}}</b-td>
            <b-td>Rp.{{v.price_total | formatNumber}}</b-td>
            <b-td class="d-flex justify-content-end">
              <b-button @click="removeItems(k)" size="sm" variant="outline-danger">X</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th>
              <b-button @click="openItemsModal" block variant="outline-primary"><feather-icon icon="MonitorIcon"></feather-icon> Cari Produk</b-button>
            </b-th>
            <b-th colspan="8"></b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <b-row>
        <b-col sm="12" xl="4" order="1" order-xl="0">
          <p>
            Jumlah Penukaran : <span class="font-weight-bolder">{{totalQty}}</span><br>
            Dilayani Oleh : <span class="font-weight-bolder">{{auth.name}}</span>
          </p>
          <form-textarea v-model="formData.note" label="Catatan Penukaran"></form-textarea>
          <b-button-group size="sm">
            <!-- <b-button variant="primary"><feather-icon icon="PrinterIcon"></feather-icon></b-button> -->
            <!-- <b-button @click="confirmReset" variant="danger"><feather-icon icon="XIcon"></feather-icon> Reset</b-button> -->
          </b-button-group>
          <!--
          <p class="small mt-1">
            Printer Status : <span class="font-weight-bolder text-danger">Disconnected</span>
            <br>
            Scanner Status : <span class="font-weight-bolder text-danger">Disconnected</span>
          </p>
          -->
        </b-col>
        <b-col sm="12" offset-sm="4" xl="4" order="0" order-xl="1">
          <form-number left-label readonly :value="totalDiff" label="Selisih"></form-number>
          <b-form-group label-cols-sm="4" label="Tipe Pembayaran" label-for="paymentTypeInput">
            <b-form-radio-group @change="paymentTypeChange" id="paymentTypeInput" button-variant="outline-primary" v-model="formData.id_payment_partner" :options="paymentTypes" name="paymentTypeInput" buttons text-field="label" value-field="value"></b-form-radio-group>
          </b-form-group>
          <form-number :disabled="totalDiff<0" :rules="{required: true, minimal: [totalDiff]}" ref="paymentInput" size="lg" left-label v-model.number="formData.payment_amount" label="Bayar"></form-number>
          <form-number disabled left-label :value="paymentChange" label="Kembalian"></form-number>
        </b-col>
      </b-row>
      <c-button :disabled="!syncStatus" class="mt-3" size="lg" block type="submit">Simpan Penukaran</c-button>
    </c-form>
    <b-modal size="lg" @shown="$refs.itemsSearch.focus()" centered ref="itemsModal" title="Cari Produk" no-close-on-backdrop hide-footer>
      <c-form @submit="importing">
        <b-row cols="1" cols-xl="2">
          <b-col>
            <form-input size="sm" ref="itemsSearch" v-model="searchItem" label="Daftar Produk" placeholder="Cari Berdasarkan Nama / Kode / Merek"></form-input>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="categoryItem" label="Kategori" :options="itemCategories" placeholder="Cari Berdasarkan Kategori"></form-select-static>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="colorItem" label="Warna" :options="itemColors" placeholder="Cari Berdasarkan Warna"></form-select-static>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="sizeItem" label="Ukuran" :options="itemSizes" placeholder="Cari Berdasarkan Ukuran"></form-select-static>
          </b-col>
        </b-row>
        <hr>
        <b-table sticky-header no-border-collapse outlined @row-selected="onItemsSelected" selectable select-mode="multi" selected-variant="primary" :fields="itemsField" :items="itemsFiltering" small responsive>
          <template #cell(sale_price)="{item}">
            Rp.{{item.sale_price | formatNumber}}
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-button type="submit" class="mr-1" variant="primary">Import</b-button>
          <b-button @click="$refs.itemsModal.hide()" variant="danger">Tutup</b-button>
        </div>
      </c-form>
    </b-modal>
    <b-modal lazy centered @shown="$refs.editQty.focus()" size="md" ref="editModalIn" title="Edit Item Produk" no-close-on-backdrop hide-footer>
      <c-form ref="editForm" @submit="submitEditIn">
        <form-input disabled :value="formEditIn.code_ref" label="Kode Penjualan"></form-input>
        <form-input disabled :value="formEditIn.brand_name" label="Merek"></form-input>
        <form-input disabled :value="formEditIn.code_article" label="Artikel"></form-input>
        <form-input disabled :value="formEditIn.color_name" label="Warna"></form-input>
        <form-input disabled :value="formEditIn.size_name" label="Ukuran"></form-input>
        <form-number ref="editQty" :rules="{required: true}" @onChange="formEditIn.price_total = formEditIn.qty * formEditIn.price" v-model.number="formEditIn.qty" label="Qty"></form-number>
        <form-number disabled :value="formEditIn.price" label="Harga"></form-number>
        <form-number disabled :value="formEditIn.price_total" label="Total Harga"></form-number>
        <b-button class="mt-2" variant="primary" block type="submit">Ubah Item Penjualan</b-button>
      </c-form>
    </b-modal>
    <b-modal lazy centered @shown="$refs.editQty.focus()" size="md" ref="editModal" title="Edit Produk" no-close-on-backdrop hide-footer>
      <c-form ref="editForm" @submit="submitEdit">
        <form-input disabled v-model="formEdit.code" label="Kode Barang"></form-input>
        <form-select-all disabled v-model="formEdit.brand" endpoint="v1/brands/select2" label="Merek"></form-select-all>
        <form-input v-model="formEdit.code_article" label="Artikel" disabled></form-input>
        <form-select-static disabled v-model="formEdit.color" :options="itemColors" label="Warna"></form-select-static>
        <form-select-static @option:selected="changeItemSize" v-model="formEdit.size" :options="sizes" label="Ukuran"></form-select-static>
        <form-number ref="editQty" :rules="{required: true}" @onChange="formEdit.price_total = formEdit.qty * formEdit.price" v-model.number="formEdit.qty" label="Qty"></form-number>
        <form-number disabled :value="formEdit.price" label="Harga"></form-number>
        <form-number disabled :value="formEdit.price_total" label="Total Harga"></form-number>
        <b-button class="mt-2" variant="primary" block type="submit">Ubah Produk</b-button>
      </c-form>
    </b-modal>
    <b-modal lazy centered ref="localModal" title="Cari Penjualan" size="xl" no-close-on-backdrop hide-footer>
      <b-tabs pills>
        <b-tab lazy title="Penjualan Hari Ini">
          <c-form @submit="onTransactionImport">
            <b-table selected-variant="primary" :fields="transactionsFields" :items="localTransactions" responsive small outlined hover borderless @row-selected="onTransactionSelected" selectable>
              <template #cell(price)="{item}">
                Rp.{{item.price | formatNumber}}
              </template>
              <template #cell(price_total)="{item}">
                Rp.{{item.price_total | formatNumber}}
              </template>
            </b-table>
            <div class="d-flex justify-content-end">
              <b-button type="submit" class="mr-1" variant="primary">Import</b-button>
            </div>
          </c-form>
        </b-tab>
        <b-tab lazy title="Penjualan Sebelumnya">
          <sale-transaction-detail @imported="inSaleImported"></sale-transaction-detail>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal lazy ref="transactionModal" title="Cari Transaksi Penjualan" hide-footer size="xl">
      <b-tabs pills>
        <b-tab lazy title="Transaksi Hari Ini">
          <Local @imported="localTransactionImported"></Local>
        </b-tab>
        <b-tab lazy title="Transaksi Sebelumnya">
          <Server @imported="serverTransactionImported"></Server>
        </b-tab>
      </b-tabs>
    </b-modal>
  </c-card>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import {BTableSimple,BThead,BTr,BTh,BFormRadioGroup,BFormGroup,BButton,BTbody,BTd,BModal,BTable,BButtonGroup,BAlert,BTfoot,BTabs,BTab} from 'bootstrap-vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import CForm from '@/utils/components/CForm.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CButton from '@/utils/components/CButton.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'
import find from 'lodash/find'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'
import findIndex from 'lodash/findIndex'
import { v4 as uuidv4 } from 'uuid'
import map from 'lodash/map'
import ScannerDetector from 'js-scanner-detection'
import helper from '../../../utils/js/helper'
import Local from './components/Local.vue'
import Server from './components/Server.vue'
import SaleTransactionDetail from './components/SaleTransactionDetail.vue'

export default {
  components: {
    CCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    FormTextarea,
    CForm,
    FormNumber,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    FormDate,
    FormInput,
    CButton,
    BTbody,
    BTd,
    BModal,
    BTable,
    FormSelectAll,
    FormSelectStatic,
    BButtonGroup,
    BAlert,
    BTfoot,
    Local,
    Server,
    BTabs,
    BTab,
    SaleTransactionDetail
  },
  data(){
    const onComplete = (barcode) => {
      this.barcodeScanned(barcode)
    }
    const options = {
      onComplete,
      stopPropagation: true,
      preventDefault: false,
    }

    const formData = {
      code:'',
      code_ref:'',
      note:'',
      date:this.$moment(),
      id_payment_partner:null,
      payment_amount:0,
      payment_change:0,
      is_sync: false,
      type_transaction: 4, // 4 = penukaran
      kind: 4 //4 = tambah, 5 = edit, 6 = hapus
    }
    const details = []
    const itemsField = [
      {key:'code',label:'Kode Barang'},
      {key:'brand_name',label:'Merek'},
      {key:'item_name',label:'Artikel'},
      {key:'color_name',label:'Warna'},
      {key:'size_name',label:'Ukuran'},
      {key:'sale_price',label:'Harga'},
    ]
    const formEdit = {
      id_variant:null,
      id_item:null,
      code:'',
      brand: null,
      color:null,
      size:null,
      qty:1,
      code_article:'',
      price:0,
      price_total:0
    }
    const inFields = [
      {key:"code_ref",label:"No. Penjualan"},
      {key:"code",label:"Kode"},
      {key:"brand_name",label:"Merek"},
      {key:"code_article",label:"Artikel"},
      {key:"color_name",label:"Warna"},
      {key:"size_name",label:"Ukuran"},
      {key:"qty",label:"Qty"},
      {key:"price",label:"Harga"},
      {key:"price_total",label:"Total"},
      {key:"uniqid",label:"",tdClass:"d-flex justify-content-end"},
    ]
    const inDetails = []
    const transactionsFields = [
      {key:"code_ref",label:"Kode"},
      {key:"code",label:"Kode Barang"},
      {key:"brand_name",label:"Merek"},
      {key:"code_article",label:"Artikel"},
      {key:"color_name",label:"Warna"},
      {key:"size_name",label:"Nomor"},
      {key:"qty",label:"Qty"},
      {key:"price",label:"Harga"},
      {key:"price_total",label:"Total"},
    ]
    return {
      formData,
      syncLoading:false,
      details,
      searchItem:'',
      colorItem:null,
      sizeItem:null,
      categoryItem:null,
      itemsField,
      selectedItems:[],
      formEdit,
      editIndex:-1,
      lastSync: localStorage.getItem('itemsLastSync'),
      inFields,
      inDetails,
      transactionsFields,
      selectedTransactions:[],
      scannerDetect : new ScannerDetector(options),
      scancode:"",
      formEditIn:{}
    }
  },
  watch:{
    '$route'(){
      this.incrementKey()
    }
  },
  computed:{
    items(){
      return this.$store.state.pos.items
    },
    itemsFiltering(){
      const filtering = {}
      if(this.colorItem) filtering.id_color = this.colorItem.value
      if(this.sizeItem) filtering.id_size = this.sizeItem.value
      if(this.categoryItem) filtering.id_category = this.categoryItem.value
      if(this.searchItem) filtering.search = this.searchItem
      filtering.excludeId = map(this.details,'id_variant')
      return this.$store.getters['pos/itemsByFilter'](filtering)
    },
    paymentTypes(){
      return this.$store.state.pos.payment_types
    },
    totalQty(){
      return sumBy(this.details,'qty')
    },
    subTotal(){
      return sumBy(this.details,n => parseFloat(n.price_total))
    },
    totalReturn(){
      return sumBy(this.inDetails,'price_total')
    },
    totalDiff(){
      return this.subTotal-this.totalReturn
    },
    grandTotal(){
      const subTotal = this.subTotal
      return subTotal
    },
    paymentChange(){
      return this.formData.payment_amount - this.totalDiff
    },
    itemColors(){
      return this.$store.getters['pos/getItemColors']
    },
    itemSizes(){
      return this.$store.getters['pos/getItemSizes']
    },
    sizes(){
      const { id_item, brand, color } = this.formEdit
      const filter = {
        item_id: id_item,
      }
      if(brand) filter.id_brand = brand.value
      if(color) filter.id_color = color.value
      return this.$store.getters['pos/getFilteredSize'](filter)
    },
    syncStatus(){
      return this.$store.getters['pos/checkSyncStatus']
    },
    localTransactions(){
      let filtering = {}
      filtering.excludeId = map(this.inDetails,'uniqid')
      filtering.noDelete = true
      return this.$store.getters['pos/onlySaleTransactions'](filtering)
    },
    itemCategories(){
      return this.$store.getters['pos/getItemCategories']
    },
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm() {
      const uniqid = uuidv4()
      const formData = JSON.parse(JSON.stringify(this.formData))
      if(!this.$route.query.uniqid&&!this.$route.query.id) {
        formData.date = this.$moment()
        formData.uniqid = uniqid
        localStorage.setItem('returnNumbering', formData.code_ref)
      }
      formData.staff = this.auth.username
      formData.difference = this.totalDiff
      formData.payment_change = this.paymentChange
      formData.payment_partner = find(this.paymentTypes,{"value":formData.id_payment_partner}).label
      formData.detail_outs = this.details
      formData.detail_ins = this.inDetails
      formData.total_item = this.totalQty
      this.$store.dispatch('pos/storeSale',formData)
      this.notify("Data Berhasil Disimpan","OK")
      this.resetForm()
    },
    confirmReset(){
      this.confirmation(`resetForm`)
    },
    resetForm() {
      this.details = []
      this.inDetails = []
      const fd = {
        code:'',
        code_ref:this.generatedCode(),
        note:'',
        date:this.$moment(),
        id_payment_partner:null,
        payment_amount:0,
        payment_change:0,
        is_sync: false,
        type_transaction: 4,
        kind: 4
      }
      this.formData = fd
      this.$router.replace({query:null})
    },
    async syncItems(){
      this.syncLoading = true
      Promise.all([
        this.$store.dispatch(`pos/getItems`),
        this.$store.dispatch(`pos/getPaymentTypes`),
      ]).then(()=>{
        if(this.paymentTypes.length>0)
          this.formData.id_payment_partner = this.paymentTypes[0].value
        this.lastSync = localStorage.getItem('itemsLastSync')
        this.syncLoading = false
      })
    },
    openItemsModal(){
      this.searchItem = ''
      this.$refs.itemsModal.show()
    },
    onItemsSelected(items){
      this.selectedItems = items
    },
    importing(){
      let dt = JSON.parse(JSON.stringify(this.details))
      for (let i = 0; i < this.selectedItems.length; i++) {
        const e = this.selectedItems[i];
        const findExist = find(dt,{id_variant:e.id})
        if(!findExist) {
          dt.push({
            uniqid: uuidv4(),
            id_variant: e.id,
            code: e.code,
            code_article: e.code_article,
            id_color: e.id_color,
            color_name: e.color_name,
            id_size: e.id_size,
            id_item: e.item_id,
            id_category: e.id_category,
            size_name: e.size_name,
            price: e.sale_price,
            id_brand: e.id_brand,
            brand_name: e.brand_name,
            qty: 1,
            price_total: e.sale_price
          })
        } else {
          const index = findIndex(this.details,{id_variant:e.id})
          this.$nextTick(()=>{
            this.details[index].qty += 1
            this.details[index].price_total = this.details[index].qty * this.details[index].price
          })
        }
      }
      this.details = dt
      this.$refs.itemsModal.hide()
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    },
    itemsEdit(key){
      this.editIndex = key
      const item = this.details[key]
      const it = {
        id_variant:item.id_variant,
        id_item:item.id_item,
        code:item.code,
        brand: {label:item.brand_name,value:item.id_brand},
        color: {label:item.color_name,value:item.id_color},
        size: {label:item.size_name,value:item.id_size},
        qty:item.qty,
        code_article:item.code_article,
        price:item.price,
        price_total:item.price_total
      }
      this.formEdit = it
      this.$refs.editModal.show()
    },
    itemsEditIn(item,index){
      this.editIndex = index
      this.$set(this,'formEditIn',JSON.parse(JSON.stringify(item)))
      this.$refs.editModalIn.show()
    },
    changeItemSize(){
      const fe = this.formEdit
      const f = find(this.items,{item_id:fe.id_item,id_color:fe.color.value,id_size:fe.size.value})
      if(f) {
        this.formEdit.id_variant = f.id
        this.formEdit.code = f.code
        this.formEdit.price = f.sale_price
        this.formEdit.price_total = f.sale_price * parseFloat(fe.qty)
      }
    },
    submitEdit(){
      const index = this.editIndex
      const fe = this.formEdit
      this.details[index].id_variant = fe.id_variant
      this.details[index].qty = fe.qty
      this.details[index].id = fe.id
      this.details[index].code = fe.code
      this.details[index].price = fe.price
      this.details[index].price_total = fe.price_total
      this.details[index].id_size = fe.size.value
      this.details[index].size_name = fe.size.label
      this.$refs.editModal.hide()
      this.editIndex = -1
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    },
    submitEditIn(){
      const index = this.editIndex
      const fe = this.formEditIn
      this.inDetails[index].qty = fe.qty
      this.inDetails[index].price_total = fe.price_total
      this.$refs.editModalIn.hide()
      this.editIndex = -1
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    },
    removeItems(key){
      this.details.splice(key, 1)
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    },
    findTransactionByUniqid(uniqid){
      const vm = this
      const data = find(this.$store.state.pos.local_transactions,{uniqid})

      if(data) {
        vm.formData.uniqid = uniqid
        vm.formData.date = data.date
        vm.formData.code_ref = data.code_ref
        vm.formData.code = data.code
        vm.formData.note = data.note
        vm.formData.id_payment_partner = data.id_payment_partner
        vm.formData.payment_amount = data.payment_amount
        vm.formData.is_sync = false
        vm.formData.type_transaction = 4
        vm.formData.kind = 5
        vm.details = data.detail_outs
        vm.inDetails = data.detail_ins
      }
    },
    generatedCode(){
      const ifNotAvailable = "RTR/"+this.$moment().format("YY/MM")+""+helper.randomize()
      const generatedCode = localStorage.getItem(`returnNumbering`)||ifNotAvailable
      return this.incrementString(generatedCode)
    },
    incrementString (str) {
      // Find the trailing number or it will match the empty string
      const count = str.match(/\d*$/);
      // Take the substring up until where the integer was matched
      // Concatenate it to the matched count incremented by 1
      // return str.substr(0, count.index) + (++count[0]);
      return str.substr(0, count.index) + ("0000" + (++count[0])).slice(-4)
    },
    onTransactionSelected(items){
      this.selectedTransactions = items
    },
    onTransactionImport(){
      const existing = JSON.parse(JSON.stringify(this.inDetails))
      this.inDetails = uniqBy([...existing,...this.selectedTransactions],'uniqid')
      this.$refs.localModal.hide()
    },
    barcodeScanned(barcode){
      Promise.all([
        this.findBarcodeOnTransaction(barcode),
        this.itemScanner(barcode)
      ])
    },
    itemScanner(barcode){
      const vm = this
      const data = find(vm.items,{code:barcode})
      if(data){
        vm.selectedItems = [data]
        vm.$nextTick(()=>{
          vm.importing()
        })
      }
    },
    paymentTypeChange(){
      const index = findIndex(this.paymentTypes,{value:this.formData.id_payment_partner})
      if(index>=0){
        const {is_cash} = this.paymentTypes[index]
        if(is_cash==0 && this.totalDiff>0) this.formData.payment_amount = this.totalDiff
        else this.formData.payment_amount = 0
      } else {
        this.formData.payment_amount = 0
      }
    },
    localTransactionImported(item){
      this.$refs.transactionModal.hide()
      this.$router.replace({query:{uniqid:item.uniqid}})
    },
    serverTransactionImported(item){
      this.$refs.transactionModal.hide()
      this.$router.replace({query:{id:item.id}})
    },
    async getTransactionFromServer(id){
      const vm = this
      vm.$store.dispatch('pos/getTransactionFromServer',{id,type:4})
      .then((data)=>{
        if(data) {
          vm.formData.uniqid = data.uniqid
          vm.formData.id_origin = data.id
          vm.formData.date = data.date
          vm.formData.code_ref = data.code_ref
          vm.formData.code = data.code
          vm.formData.note = data.note
          vm.formData.id_payment_partner = data.id_payment_partner
          vm.formData.payment_amount = data.payment_amount
          vm.formData.is_sync = false
          vm.formData.type_transaction = 4
          vm.formData.kind = 5
          vm.inDetails = map(data.detail_ins,(n)=>{
            const dn = JSON.parse(JSON.stringify(n))
            dn.id_origin = dn.id
            delete dn.id
            return dn
          })
          vm.details = map(data.detail_outs,(n)=>{
            const dn = JSON.parse(JSON.stringify(n))
            dn.id_origin = dn.id
            delete dn.id
            return dn
          })

        }
      })
    },
    inSaleImported(itemRaw){
      const arr = JSON.parse(JSON.stringify(itemRaw))
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const index = findIndex(this.inDetails,{id_header: item.id_header, id_color: item.id_color, id_size: item.id_size, id_item: item.id_item})
        if(index>=0) continue;
        item.code = item.code_barcode
        delete item.id
        delete item.code_sale
        delete item.code_barcode
        this.inDetails.push(item)
      }
      this.$refs.localModal.hide()
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    },
    findBarcodeOnTransaction(code=''){
      this.$http.get(`v1/pos/findbarcode`,{params:{code}}).then(({data}) => {
        for (let i = 0; i < data.length; i++) {
          let item = JSON.parse(JSON.stringify(data[i]))
          const index = findIndex(this.inDetails,{uniqid: item.uniqid_detail})
          if(index>=0) continue

          item.code = item.code_sale
          item.code_ref = item.code_sale_ref
          item.uniqid = item.uniqid_detail
          delete item.code_sale
          delete item.code_sale_ref
          delete item.uniqid_detail
          delete item.uniqid_sale
          delete item.code_barcode
          delete item.date_sale
          delete item.id_sale
          delete item.id_sale_detail
          this.inDetails.push(item)
        }
        this.$nextTick(() => {
          this.paymentTypeChange()
        })
      })
    },
    deleteInDetail(i){
      this.inDetails.splice(i, 1)
      this.$nextTick(() => {
        this.paymentTypeChange()
      })
    }
  },
  mounted(){
    if(this.paymentTypes.length>0)
      this.formData.id_payment_partner = this.paymentTypes[0].value
    // this.$refs.paymentInput.focus()
    if(this.$route.query.uniqid) {
      this.findTransactionByUniqid(this.$route.query.uniqid)
    } else if(this.$route.query.id){
      this.getTransactionFromServer(this.$route.query.id)
    } else {
      this.formData.code_ref = this.generatedCode()
    }
  }
}
</script>

<style>

</style>