<template>
  <div>
    <b-card title="Filter">
      <c-form ref="filterForm" @submit="filterSubmit">
        <div class="d-flex justify-content-between">
          <form-date :max="filters.date_2" class="w-100 mr-1" v-model="filters.date_1" label="Periode Awal"></form-date>
          <form-date :min="filters.date_1" class="w-100" v-model="filters.date_2" label="Periode Akhir"></form-date>
        </div>
        <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
        <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
      </c-form>
    </b-card>
    <pagination-table ref="table" sort-key="code_sale" sort-desc selected-variant="primary" selectable @row-selected="rowSelected" endpoint="v1/pos/saledetails" :fields="fields" :queryParams="filters">
      <template v-slot:price="{item}">
        Rp.{{item.price | formatNumber}}
      </template>
      <template v-slot:price_total="{item}">
        Rp.{{item.price_total | formatNumber}}
      </template>
    </pagination-table>
    <div class="d-flex justify-content-end">
      <BButton variant="primary" @click="choose">Import</BButton>
    </div>
  </div>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import FormDate from '@/utils/components/FormDate.vue'
import CForm from '@/utils/components/CForm.vue'
import {BButton, BCard} from 'bootstrap-vue'
const moment = require('moment')

const filters = {
  date_1: moment().startOf('month'),
  date_2: moment().endOf('month'),
}
export default {
  components: { PaginationTable, BButton, BCard, FormDate, CForm },
  data(){
    return {
      fields:[
        {key:"code_sale",label:"No. Penjualan"},
        {key:"code_barcode",label:"Kode"},
        {key:"brand_name",label:"Merek"},
        {key:"code_article",label:"Artikel"},
        {key:"color_name",label:"Warna"},
        {key:"size_name",label:"Ukuran"},
        {key:"qty",label:"Qty"},
        {key:"price",label:"Harga"},
        {key:"price_total",label:"Total Harga"},
      ],
      selected:[],
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  methods:{
    rowSelected(item){
      this.selected = item
    },
    filterSubmit(){
      this.$refs.table.refresh()
    },
    resetFilter(){
      const vm = this
      this.filters = JSON.parse(JSON.stringify(filters))
      vm.$nextTick(()=>{
        vm.$refs.table.refresh()
      })
    },
    choose(){
      if(this.selected.length==0) return this.notify("Pilih salah satu transaksi terlebih dahulu!","Oops!","danger")
      this.$emit('imported', this.selected)
    }
  }
}
</script>

<style>

</style>