<template>
  <div>
    <b-card title="Filter">
      <c-form ref="filterForm" @submit="filterSubmit">
        <div class="d-flex justify-content-between">
          <form-date :max="transactionFilter.date_2" class="w-100 mr-1" v-model="transactionFilter.date_1" label="Periode Awal"></form-date>
          <form-date :min="transactionFilter.date_1" class="w-100" v-model="transactionFilter.date_2" label="Periode Akhir"></form-date>
        </div>
        <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
        <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
      </c-form>
    </b-card>
    <pagination-table ref="table" sort-key="date" sort-desc selected-variant="primary" selectable select-mode="single" @row-selected="rowSelected" endpoint="v1/pos/index" :fields="fields" :queryParams="filterParams">
      <template v-slot:date="{item}">
        {{item.date | mindate}}
      </template>
      <template v-slot:grand_total="{item}">
        Rp.{{item.grand_total | formatNumber}}
      </template>
    </pagination-table>
    <div class="d-flex justify-content-end">
      <BButton variant="primary" @click="choose">Pilih</BButton>
    </div>
  </div>
</template>

<script>
import CForm from '@/utils/components/CForm.vue'
import FormDate from '@/utils/components/FormDate.vue'
import { mapFields } from 'vuex-map-fields'
import {BButton,BCard} from 'bootstrap-vue'
import PaginationTable from '@/utils/components/PaginationTable.vue'

export default {
  components:{CForm,FormDate,BButton,BCard, PaginationTable},
  data(){
    return {
      fields:[
        {key:"code_ref",label:"No. Transaksi"},
        {key:"date",label:"Tanggal"},
        {key:"payment_partner_name",label:"Pembayaran"},
        {key:"grand_total",label:"Total Harga"},
      ],
      selected:[]
    }
  },
  computed:{
    ...mapFields('filter',['transactionFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.transactionFilter))
      let fd = {}
      if(dt.date_1) fd.date_1 = dt.date_1
      if(dt.date_2) fd.date_2 = dt.date_2
      fd.type_transactions = 4
      return fd
    }
  },
  methods:{
    rowSelected(item){
      this.selected = item
    },
    filterSubmit(){
      this.$refs.table.refresh()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_TRANSACTIONS')
      vm.$nextTick(()=>{
        vm.$refs.table.refresh()
      })
    },
    choose(){
      if(this.selected.length==0) return this.notify("Pilih salah satu transaksi terlebih dahulu!","Oops!","danger")
      this.$emit('imported', this.selected[0])
    }
  }
}
</script>

<style>

</style>